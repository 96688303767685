<template>
  <div>
    <v-dialog
      v-model="openDialog"
      class="dialogAddPayment"
      width="500"
      persistent
    >
      <v-card>
        <v-card-title class="headline info white--text">
          Agregar permisos para autorizar
        </v-card-title>

        <v-card-text class="pt-4">
          <v-form
            ref="form"
            v-model="validForm"
            lazy-validation
          >
            <loading :active.sync="myLoading.isLoading" :is-full-page="myLoading.fullPage"></loading>
            <v-row>
              <v-col cols="12">
                <v-select
                  v-model="user"
                  :items="items"
                  menu-props="auto"
                  label="Seleccione el usuario"
                  hide-details
                  prepend-icon="mdi-account"
                  item-text="name"
                  item-value="id"
                  :rules="[rules.required]"
                  single-line
                ></v-select>
              </v-col>
              <v-col cols="12" md="12">
                <v-text-field
                  label="Ingrese la contraseña"
                  prepend-icon="mdi-lock"
                  color="primary"
                  v-model="password"
                  required
                  :rules="[rules.required, rules.min]"
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="12">
                <v-text-field
                  label="Confirme la contraseña"
                  prepend-icon="mdi-lock-check"
                  color="primary"
                  v-model="passwordConfirm"
                  required
                  :rules="[rules.required, rules.min, rules.equal]"
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="closeDialog"
          >
            Cerrar
          </v-btn>
          <v-btn
            color="primary"
            :disabled="!validForm"
            @click="validateForm"
          >
            <v-icon>mdi-conten-save</v-icon> Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar.show"
      :color="snackbar.color"
      :timeout="-1"
    >
      {{snackbar.message}}
      <template v-slot:action="{ attrs }">
        <v-btn
          color=""
          text
          v-bind="attrs"
          @click="snackbar.show = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
  import User from "@/models/User.js";
  export default {
    name: 'UserAddPermissions',
    props:{
      open: {
        type: Boolean,
        required: true,
      }
    },
    data() {
      return {
        openDialog:this.open,
        model: new User,
        validForm:true,
        myLoading:{
          isLoading: true,
          fullPage:false
        },
        snackbar:{
          show:false,
          color:'',
          message:''
        },
        passwordConfirm: '',
        password: '',
        items: null,
        user: null,
        rules: {
          required: (v) => (v && v.length > 0) || "Campo requerido",
          min: (v) => v.length >= 8 || "Min 8 caracteres",
          equal:(v) => {
            if (this.password !== v) {
              return 'Las contraseñas no coinciden'
            }
            return true
          }
        },
      }
    },
    mounted() {
      this.model.getUsersPermissions(false).onSnapshot(snap => {
        this.items = [];
        snap.forEach(doc => {
          this.items.push(doc.data());
        })
        this.myLoading.isLoading = false
      })
    },
    methods: {
      closeDialog() {
        this.openDialog = false;
        this.passwordConfirm = ''
        this.password = ''
        this.user = null
        this.validForm = true
        this.$emit('update:open', false)
      },
      async validateForm() {
        if (this.$refs.form.validate()) {
          this.myLoading.isLoading = true
          //console.log('user', this.user);
          this.model.update(this.user, {
            hasPermissions: true,
            permissionPassword: this.password
          })
          .then(() => {
            this.myLoading.isLoading = false
            this.closeDialog()
          })
        }
      },
    },
    watch:{
      open(newValue) {
        this.openDialog = newValue;
      }
    }
  }
</script>