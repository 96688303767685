<template>
  <div>
    <portal to="title-page">
      <h1>Usuarios con permisos de Autorización</h1>
    </portal>
    <v-data-table v-if="items != null"
      v-model="selected"
      :headers="tableHeaders"
      :items="items"
      item-key="id"
      :search="search"
      fixed-header
      class="elevation-1"
    >
      <!-- show-select -->
      <!-- :single-select="singleSelect" -->
      <template v-slot:top>
        <div class="table-top-bar">
          <v-row>
            <v-col cols="6" sm="6" md="6">
              <v-text-field
                v-model="search"
                prepend-icon="mdi-magnify"
                label="Buscar"
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="6" md="6">
              <v-btn small color="primary" @click="openDialogAdd = true"><v-icon>mdi-plus-thick</v-icon> Usuario</v-btn>
            </v-col>
          </v-row>
        </div>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon 
          class="mr-2"
          @click="setItem(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon class="mr-2" color="red" @click="deleteItem(item)">mdi-delete</v-icon>
      </template>
    </v-data-table>
    <v-snackbar
      v-model="snackbar.show"
      :color="snackbar.color"
      :timeout="-1"
    >
      {{snackbar.message}}
      <template v-slot:action="{ attrs }">
        <v-btn
          color=""
          text
          v-bind="attrs"
          @click="snackbar.show = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <AddPermissions :open.sync="openDialogAdd" />
    <EditPermissions v-if="item" :open.sync="openDialogEdit" :user="item" />
  </div>
</template>
<script>
  import AddPermissions from "@/components/users/AddPermissions";
  import EditPermissions from "@/components/users/EditPermissions";
  import User from "@/models/User.js";
  export default {
    name: 'UsersPermissions',
    components: { AddPermissions, EditPermissions },
    data: function () {
      return {
        model:new User,
        ////////////////////////////
        selected:[],
        tableHeaders:[
          {
            text: 'Email',
            align: 'start',
            sortable: true,
            value: 'email',
          },
          {
            text: 'role',
            align: 'start',
            sortable: true,
            value: 'role',
          },
          {
            text: 'Nombre',
            align: 'start',
            sortable: false,
            value: 'name',
            filterable:false
          },
          { text: 'Actions', value: 'actions', sortable: false },
        ],
        item:null,
        items:null,
        search:'',
        snackbar:{
          show:false,
          color:'',
          message:''
        },
        openDialogAdd : false,
        openDialogEdit : false,
        selectedItem : null,
        validFormStatus:true,
      }
    },
    created () {
      this.$store.commit('SET_LAYOUT', 'admin')
    },
    methods:{
      showSnackbarSuccess(message) {
        this.snackbar.show = true;
        this.snackbar.message = message;
        this.snackbar.color = 'green';
      },
      showSnackbarError(message) {
        this.snackbar.show = true;
        this.snackbar.message = message;
        this.snackbar.color = 'red';
      },
      deleteItem(item) {
        // console.log(item);
        //this.dialog.show = true;
        this.$dialog
        .confirm(`¿Desea eliminar los permisos del Usuario <strong>${item.name}</strong>?`, {
          loader: true,
        })
        .then((dialog) => {
          this.model
            .update(item.id, {hasPermissions: false})
            .then(async () => {
              dialog.close();
              this.showSnackbarSuccess(`Los permisos se eliminaron correctamente`)
            })
            .catch((error) => {
              console.error("Error removing document: ", error);
              dialog.close();
              this.showSnackbarError(`Error al eliminar el tour, por favor contacte al Administrador si el problema persiste`)
            });
        })
        .catch(() => {
          console.log("Delete aborted");
        });
      },
      setItem(item) {
        this.item = item
        this.openDialogEdit = true
      }
    },
    mounted() {
      this.model.getUsersPermissions(true).onSnapshot(snap => {
        this.items = [];
        snap.forEach(doc => {
          this.items.push(doc.data());
        })
      })
    }
  }
</script>